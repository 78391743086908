import React, { useEffect, useState } from 'react';
import me from '../../data/me'

const Nav: React.FC = () => {
  let [scroll, setScroll] = useState<boolean>()
  const styling: string = scroll ? "navbar scrolling-active" : "navbar"
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll) 
  })

  const handleScroll = () => {
    let position = window.scrollY
    setScroll(position > 0)
  }

  return (
    <div className={styling}>
      <div className="logo">
        eg  
      </div>
      <div className="links">
        <a href={me.github} target="_blank" rel="noopener noreferrer">github</a>
        <a href={me.linkedin} target="_blank" rel="noopener noreferrer">Linkedin</a>
        <a href={me.email} target="_blank" rel="noopener noreferrer">Email</a>
        <a href={me.resume} target="_blank" rel="noopener noreferrer">Resume</a>
      </div>
    </div>
  );
};

export default Nav;