import React from 'react';
// import { FiGithub, FiLinkedin, FiMail } from 'react-icons/fi'

const Footer = () => {
  return (
    <div className="footer">
      <span>Created by Ernesto Godoy ©</span>
    </div>
  );
};

export default Footer;