import React from 'react';

const Page404 = () => {
  return (
    <div className="page-404">
      <div className="404-inner">
        <h1>404</h1>
        <h2>No match found for <code>{ window.location.pathname}</code></h2>
      </div>
    </div>
  );
};

export default Page404;