import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Portfolio from './components/portfolio/Portfolio';
import Page404 from './Page404';

const App: React.FC = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={ Portfolio } />
          <Route component={ Page404 } />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
