import React from 'react';

// interface ContactForm { 
//   name: string, 
//   email: string, 
//   body: string 
// }

// const encode = (data: any) => {
//   return Object.keys(data)
//           .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//           .join("&");
// }

const ContactMe: React.FC = () => {
  // const [form, setForm] = useState<ContactForm>({name: '', email: '', body: ''})

  // const handleChange = (e: any) => {
  //   const { name, value } = e.target
  //   setForm({ ...form,
  //     [name]: value
  //   })
  // }


  const handleSubmit = (e: any) => {
    // console.log(form)
    // fetch("/", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: encode({ 
    //     "form-name": "contact", 
    //     ...form 
    //   })
    // })
    // .then(() => alert("Success!"))
    // .catch(error => alert(error));
    alert("Success!")
  }

  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <div className="line"></div>
      <div className="contact-inner">
        <form netlify-data="true" method="POST" name="contact-form" onSubmit={ handleSubmit }>
        <input type="hidden" name="form-name" value="contact-form" />
          <div className="form-input name-email">
            <input 
              required 
              type="text" 
              name="name" 
              placeholder="Enter Name" 
            />
            <input 
              required 
              type="email" 
              name="email" 
              placeholder="Enter Email"
            />
          </div>
          <div className="form-input body">
            <textarea 
              required 
              name="body" 
              placeholder="Body"
            ></textarea>
          </div>
          <div className="form-input submit">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;